import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Ng2OrderModule } from 'ng2-order-pipe';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { JwSocialButtonsModule } from 'jw-angular-social-buttons';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireAuthModule} from '@angular/fire/auth';

import { DashboardComponent } from './dashboard/dashboard.component';
import {GdprComponent} from './gdpr/gdpr.component';

import { DataService } from './services/data.service';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    GdprComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    Ng2SearchPipeModule,
    Ng2OrderModule,
    FormsModule,
    ReactiveFormsModule,
    JwSocialButtonsModule
  ],
  providers: [DataService],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
