// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    // apiKey: "AIzaSyC5-ulUfrR08gnDwQowFKVUpylUWAZWPc4",
    // authDomain: "itclub-80114.firebaseapp.com",
    // databaseURL: "https://itclub-80114.firebaseio.com",
    // projectId: "itclub-80114",
    // storageBucket: "itclub-80114.appspot.com",
    // messagingSenderId: "1033202491272",
    // appId: "1:1033202491272:web:de528140c7f541f1"

    apiKey: "AIzaSyCYFvC1l9lb5o1Dxl0BMWiUS5q-VqnvezU",
    authDomain: "it-club-10.firebaseapp.com",
    databaseURL: "https://it-club-10.firebaseio.com",
    projectId: "it-club-10",
    storageBucket: "it-club-10.appspot.com",
    messagingSenderId: "869920350933",
    appId: "1:869920350933:web:7c036407f90ac503"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
