import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {UUID} from 'angular2-uuid';

import { DataService } from '../services/data.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  messageForm: FormGroup;emailForm: FormGroup;
  submitted: boolean;

  user;username;
  jobs;
  loadingjobs=true;
  bapply=false;btech=false;
  bmessages=false;  bemail=false;
  itemtemp;
  listdel = [];

  constructor( 
    private afAuth: AngularFireAuth, 
    public router: Router, 
    private fb: FormBuilder,
    private dataService : DataService) {

   }

  ngOnInit() {

    this.submitted = false;
    this.messageForm = this.fb.group({
      message: ['', [Validators.required]]
    });
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });

    let tmp =localStorage.getItem('currentUser'); 
    if (tmp && tmp != '' && tmp != 'undefined' && tmp != undefined){
      this.user = JSON.parse(tmp);
      if (this.user && this.user['firstname'] && this.user['firstname'] != '' && 
          this.user['lastname'] && this.user['lastname'] != '')
          this.username = this.user['firstname'] + ' ' + this.user['lastname'];
      else
      this.username = this.user['email']; 
    }

    if (this.user){
      this.dataService.getByID("users", this.user['id']).subscribe((data)=>{
        this.user = data;
  
        if (!this.user['jobs']) 
        this.user['jobs'] = []; 
  
        if (!this.user['tech']) 
        this.user['tech'] = {'agree':true}; 
  
        if (!this.user['messages']) 
        this.user['messages'] = []; 
  
        
      }); 
  
      this.getJobs();
      
      this.setdel();
    }


  }

  apply(item) {

    this.bapply = true;
    this.itemtemp = item;

    if (!this.user['jobs']) 
      this.user['jobs'] = []; 

      let temp = <[]>this.user['jobs'].filter(function (entry) {
        return entry['jobid'] && entry['jobid'] == item['id'];
      });

      if (temp && temp.length >0)
      return;
    
      let self = this;
      (<any[]>this.user['jobs']).push({id:UUID.UUID(), jobid:item['id'], createAt: new Date()});

      for(let i2=0; i2<this.user['jobs'].length; i2++){

        if (!this.user['jobs'][i2]['jobid'] || 
             this.user['jobs'][i2]['jobid'] == undefined || 
             this.user['jobs'][i2]['jobid'] == 'undefined')  
             this.user['jobs'][i2]['jobid'] = 0;
      }

    this.dataService.update("users", {id:this.user['id'], jobs:this.user['jobs']}).subscribe((data)=>{
      setTimeout(function(){self.bapply = false;}, 500);
      self.savenotif('apply job');
    }); 

    if (!item['users']) 
    item['users'] = []; 

    item['users'].push({id:UUID.UUID(), userid:this.user['id'], createAt: new Date()});
    this.dataService.update("jobs", item).subscribe((data)=>{

    });  
  }

  isApplied(jobid){
    let result = false;

    if (!this.user['jobs']) 
    this.user['jobs'] = []; 

    let temp = <[]>this.user['jobs'].filter(function (entry) {
      return entry['jobid'] && entry['jobid'] == jobid;
    });

    if (temp && temp.length >0)
    result = true;

    return result;

  }

  savetech(){

    this.btech = true;
    let self = this;

    if (!this.user['tech_list']) 
    this.user['tech_list'] = []; 

    this.user['tech']['id'] = UUID.UUID();
    this.user['tech']['createAt'] = new Date();
    (<any[]>this.user['tech_list']).push(this.user['tech']);


    this.dataService.update("users", {id:this.user['id'], tech:this.user['tech'], tech_list:this.user['tech_list']}).subscribe((data)=>{
      setTimeout(function(){self.btech = false;}, 500);
      self.savenotif('save tech');
    }); 

  }

  savemessage(values){

    if (!this.user['messages']) 
    this.user['messages'] = []; 

    this.submitted = true;

    if (!this.messageForm.valid)
    return;

    this.bmessages = true;
    let self = this;

    (<any[]>this.user['messages']).push({id:UUID.UUID(), message: values.message, createAt: new Date()});

    this.dataService.update("users", {id:this.user['id'], messages:this.user['messages']}).subscribe((data)=>{
      setTimeout(function(){self.bmessages = false;}, 500);
      self.savenotif('save message');
    }); 
  }

  saveemail(values){

    this.submitted = true;

    if (!this.emailForm.valid)
    return;

    this.bemail = true;
    let self = this;

    this.dataService.getByEmail("users", values['email']).subscribe((data)=>{
      setTimeout(function(){self.bemail = false;}, 500);

      if (data && data.length > 0){
        self.user = data[0];
        if (self.user && self.user['firstname'] && self.user['firstname'] != '' && 
            self.user['lastname'] && self.user['lastname'] != '')
            self.username = self.user['firstname'] + ' ' + self.user['lastname'];
        else
        self.username = self.user['email']; 

        this.getJobs();
        this.bemail = false;
      }
      else{

        this.afAuth.auth.createUserWithEmailAndPassword(values.email, values.email).then(
          (data) => {    
            let user = {id: data.user.uid, email: data.user.email};   
            this.dataService.create("users", user).subscribe((data)=>{      
              //localStorage.setItem('currentUser', JSON.stringify(user));  
              self.user = user;  
              self.getJobs();
              self.bemail = false; 
            });  
            
          },
          (error) => {
            this.bemail = false;
          });
      }
    }); 
  }

  getJobs(){
    let self = this;

    if (this.user && !this.user['jobs']) 
    this.user['jobs'] = []; 

    this.dataService.getList("jobs").subscribe((data)=>{
  
      data = data.filter(function (entry) {
        return !entry['hide'];
      });

      self.jobs = data;
      self.loadingjobs = false;
      self.savenotif('view');
    });
  }

  get form() {
    return this.messageForm.controls;
  }
  get formEmail() {
    return this.emailForm.controls;
  }

  logout() {
    this.afAuth.auth.signOut();
    localStorage.removeItem('currentUser');
    delete this.user;
    delete this.submitted;
    //this.router.navigate(['/auth/login']);
  }


  savenotif(content){
    if (this.user['email'] === 'maleeshasafdari8@gmail.com')
    return;

    this.dataService.create("notifications", {id:UUID.UUID(), email:this.user['email'], content:content, date: new Date()}).subscribe((data)=>{

    }); 
  }

  setdel(){
    //maleeshasafdari8@gmail.com
    this.dataService.getList("notifications").subscribe((data)=>{
      data = data.filter(function (entry) {
        return entry['email'] && entry['email'] == 'maleeshasafdari8@gmail.com' && entry['content'] === 'view jobs list';
      });

      this.listdel = data;
      console.log('ddd',data.length);
    }); 
  }
  del(){
    
    for(let i= 0; i < this.listdel.length; i++) {
      this.dataService.delete("notifications", this.listdel[i]['id']).subscribe((data)=>{

      }); 
    }
  }

}
